import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import Nullish from '@/components/nullish';
import { TextLink } from '@/components-new/text';
import { ColumnDefinition, ColumnDefinitionDataType } from '@/features/state-reports/new/types/state-report';
import { formatNumber } from '@/utils/formatting';
import { isValidUrl } from '@/utils/urls';

// Builds dynamic column defs for state reports.
export const useStateColumnDefs = (columnDefinitions: ColumnDefinition[]) => {
  return useMemo(() => {
    const colDefs: ColumnDef<any>[] = columnDefinitions.map(columnDefinition => {
      return {
        header: columnDefinition.header,
        accessorKey: columnDefinition.propertyName,
        cell: (props) => render(columnDefinition.dataType, props.getValue())
      };
    }) || [];

    return colDefs;
  }, [columnDefinitions]);
};

const render = ((dataType: ColumnDefinitionDataType, value: any) => {
  if (!value) return '';
  switch (dataType) {
    case ColumnDefinitionDataType.STRING:
      return <div className="text-wrap"><Nullish value={value} /></div>;
    case ColumnDefinitionDataType.INTEGER:
      return <div className="text-right">{formatNumber(value, 0)}</div>;
    case ColumnDefinitionDataType.BOOLEAN:
      return <div>{value.toLowerCase() == 'true' ? 'Yes' : 'No'}</div>;
    case ColumnDefinitionDataType.DATE_ONLY:
      return <div><Nullish value={value} /></div>;
    case ColumnDefinitionDataType.URL:
      return <div className="text-wrap">{isValidUrl(value) ? <TextLink href={value} target="_blank">{value}</TextLink> : <Nullish value={value} />}</div>;
    case ColumnDefinitionDataType.MULTILINE_STRING:
      return <div className="whitespace-pre-wrap">{value}</div>;
    default:
      return <span>{value}</span>;
  }
});
