
export enum StateReportType {
  STANDARD = 'Standard',
  PREMIUM = 'Premium',
  CUSTOM = 'Custom'
}

export type ColumnDefinition = {
  header: string;
  propertyName: string;
  dataType: ColumnDefinitionDataType;
}

export enum ColumnDefinitionDataType {
  STRING = 'String',
  INTEGER = 'Integer',
  BOOLEAN = 'Boolean',
  DATE_ONLY = 'DateOnly',
  URL = 'Url',
  MULTILINE_STRING = 'MultilineString'
}

export type StateReport = {
  key: string;
  name: string;
  description: string;
  type: StateReportType;
  columnDefinitions: ColumnDefinition[];
};

