import { ProtectedRoute } from '@shared/auth';
import React from 'react';

import { StatesProvider } from '@/features/state-reports/components';
import StateReportsPage from '@/features/state-reports/new/components/state-reports-page';
import { useCurrentUser } from '@/hooks/use-current-user';

const StateReportsLayout = () => {
  const { hasPolicies } = useCurrentUser();
  const isClientUser = hasPolicies(['isClientUser']);

  return isClientUser ?
    <StateReportsPage /> :
    <ProtectedRoute component={StatesProvider} policies={['isAnyArtiaUser']} />;
};

export default StateReportsLayout;
