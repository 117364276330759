import { useQuery } from '@tanstack/react-query';

import { StateReport } from '@/features/state-reports/new/types/state-report';
import { artiaProtectedApi } from '@/lib/api-client';

// STATE REPORTS
const getStateReports = async (): Promise<StateReport[]> => {
  return artiaProtectedApi.get('/state-reports');
};

export const useStateReports = () => {
  return useQuery({
    queryKey: ['state-reports'],
    queryFn: async () => getStateReports()
  });
};

// REPORT DATA
const getStateReportData = async (stateReportKey: string): Promise<[]> => {
  return artiaProtectedApi.get(`/state-reports/${stateReportKey}`);
};

export const useStateReportData = (stateReportKey: string | undefined, canAccessReport: boolean) => {
  return useQuery({
    queryKey: ['state-reports', stateReportKey],
    queryFn: async () => getStateReportData(stateReportKey!),
    enabled: Boolean(canAccessReport && stateReportKey)
  });
};
