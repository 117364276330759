import React, { useState } from 'react';
import { LiaFileMedicalSolid } from 'react-icons/lia';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useStateReports } from '@/features/state-reports/new/api';
import StateReportListItem from '@/features/state-reports/new/components/state-report-list-item';
import StateReportTable from '@/features/state-reports/new/components/state-report-table';
import { StateReport, StateReportType } from '@/features/state-reports/new/types/state-report';
import { useCurrentUser } from '@/hooks/use-current-user';

const StateReportsPage = () => {
  const { user: currentUser } = useCurrentUser();
  const canAccessPremiumStateReports = currentUser.premiumFeatures.includes('state_reports_premium');
  const reportList = useStateReports().data || [];
  const [selectedReport, setSelectedReport] = useState<StateReport>();

  const canAccessReport = canAccessPremiumStateReports || selectedReport?.type != StateReportType.PREMIUM;

  return (
    <Page
      title="State Reports"
      header={({ title }) => (
        <>
          <Breadcrumbs breadcrumbs={[{ label: 'State Reports', url: '/client-state-reports' }]} />
          <PageTitleRow title={title}/>
        </>
      )}
    >
      <div className="flex h-screen overflow-hidden">
        <ul role="list" className="flex w-96 flex-col gap-y-2 overflow-scroll border-r pr-4">
          <li className="text-lg font-bold">Standard Reports</li>
          {reportList.filter(stateReport => stateReport.type == StateReportType.STANDARD).map(stateReport =>
            <StateReportListItem key={stateReport.key} stateReport={stateReport} isSelected={selectedReport?.key == stateReport.key} onClick={setSelectedReport} />
          )}
          <li className="text-lg font-bold">Premium Reports</li>
          {reportList.filter(stateReport => stateReport.type == StateReportType.PREMIUM).map(stateReport =>
            <StateReportListItem key={stateReport.key} stateReport={stateReport} isSelected={selectedReport?.key == stateReport.key} onClick={setSelectedReport} />
          )}
        </ul>

        <div className="ml-8 w-full overflow-scroll">
          {selectedReport &&
            <StateReportTable stateReport={selectedReport} canAccessReport={canAccessReport} />
          }
          {!selectedReport &&
            <EmptyState>
              <LiaFileMedicalSolid className="size-12 text-gray-500"/>
              <EmptyStateHeading>Select a report to get started.</EmptyStateHeading>
              <EmptyStateBody>
              </EmptyStateBody>
            </EmptyState>
          }
        </div>
      </div>
    </Page>
  );
};

export default StateReportsPage;
