import React from 'react';

import { StateReport } from '@/features/state-reports/new/types/state-report';

type StateReportListItemProps = {
  stateReport: StateReport;
  isSelected: boolean;
  onClick: (stateReport: StateReport) => void;
};

const StateReportListItem = ({ stateReport, isSelected, onClick }: StateReportListItemProps) => {
  return (
    <li key={stateReport.key}>
      {isSelected ?
        <span className="cursor-default font-bold">{stateReport.name}</span>
        : <span className="cursor-pointer hover:font-bold" onClick={() => onClick(stateReport)}>{stateReport.name}</span>
      }
    </li>
  );
}

export default StateReportListItem;
