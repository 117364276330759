import { getCoreRowModel, Row } from '@tanstack/react-table';
import { download, generateCsv,mkConfig } from 'export-to-csv';
import React, { useMemo } from 'react';
import { LiaEyeSlashSolid } from 'react-icons/lia';

import { Button } from '@/components-new/button';
import { DataTable } from '@/components-new/data-table';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { SectionDescription, SectionHeading } from '@/components-new/section';
import { useStateReportData } from '@/features/state-reports/new/api';
import { StateReport } from '@/features/state-reports/new/types/state-report';
import { useStateColumnDefs } from '@/features/state-reports/new/use-state-column-defs';
import { useTable } from '@/lib/react-table';

type StateReportsTableProp = {
  stateReport: StateReport | undefined;
  canAccessReport: boolean;
};

const StateReportsTable = ({ stateReport, canAccessReport }: StateReportsTableProp) => {
  const { data: stateReportData } = useStateReportData(stateReport?.key, canAccessReport);
  const columns = useStateColumnDefs(stateReport?.columnDefinitions ?? []);

  const data = useMemo(() => (stateReportData ?? []), [stateReportData]);
  const table = useTable({ data, columns, getCoreRowModel: getCoreRowModel() });

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    filename: `capsule-state-report-${stateReport?.key}`,
    columnHeaders: stateReport?.columnDefinitions.map((columnDef) => ({ key: columnDef.propertyName , displayLabel: columnDef.header })),
    boolDisplay: { true: 'Yes', false: 'No' }
  });

  const onExport = (rows: Row<any>[]) => {
    const rowData = rows.map((row) => Object.keys(row.original).reduce((acc, key) => {
        if (row.original[key] === null) {
          acc[key] = undefined;
        } else if (row.original[key] === 'True' || row.original[key] === 'False') {
          acc[key] = row.original[key].toLowerCase() === 'true';
        } else {
          acc[key] = row.original[key];
        }
        return acc;
      }, {} as any));
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  return (
    <>
      <div className="mb-8 flex">
        <div className="basis-3/4">
          <SectionHeading level={2}>{stateReport?.name}</SectionHeading>
          <SectionDescription>
            {stateReport?.description}
          </SectionDescription>
        </div>
        <div className="basis-1/4">
          {canAccessReport &&
            <Button className="float-right m-1" onClick={() => onExport(table.getRowModel().rows)}>Export</Button>
          }
        </div>
      </div>
      <DataTable table={table} />
      {!canAccessReport &&
        <EmptyState>
          <LiaEyeSlashSolid className="size-12 text-gray-500"/>
          <EmptyStateHeading>You don't have access to this report.</EmptyStateHeading>
          <EmptyStateBody>
            Contact Artia to upgrade your subscription.
          </EmptyStateBody>
        </EmptyState>
      }
    </>
  );
};

export default StateReportsTable;
